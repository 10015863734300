<template>
  <!-- <Dropdown
    :model-value="GroupLabel"
    :options="GroupLabels"
    class="mt-4 z-1"
    @update:model-value="itemService?.changeGroupLabel"
    :pt="{
      input: {
        class: 'text-xl sm:text-4xl md:text-6xl'
      },
      item: {
        class: 'text-xl sm:text-xl md:text-3xl'
      }
    }"
  /> -->
  <ShareLink class="w-full flex justify-content-center z-1 mt-3" />
    <div style="z-index: 99;position: absolute;" v-show="workshopOcc != 15">Rejoignez-nous pour un Workshop au sein de Tekab (nombre max 15 personnes).</div>
    <div style="z-index: 99;position: absolute;" v-show="workshopOcc == 15">Le nombre maximal de Workshops a été atteint.</div>
  <div ref="container" class="flex spin-container">
    <picture>
      <source srcset="/img/image.avif" type="image/avif" />
      <source srcset="/img/image.webp" type="image/webp" />
      <img src="/img/image.png" class="image" alt="background image" />
    </picture>
    <div
      class="icon"
      @click="spin"
      v-tooltip.bottom="{
        value: `↻ Spin!`,
        class: 'text-xl',
        escape: true
      }"
      tabindex="0"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, watch } from 'vue';
import random from 'random';
import { Wheel } from 'spin-wheel/dist/spin-wheel-esm';
import { useDialog } from 'primevue/usedialog';
import { TickSound, LabelLength } from '@/services/SettingService';
import { GroupLabel, GroupLabels, ItemService, Items } from '@/services/ItemService';
import CongratulationDialog from '@/components/CongratulationDialog.vue';
const itemService = inject<ItemService>('ItemService');
const properties = {
  // debug: import.meta.env.DEV,
  isInteractive: false,
  radius: 0.48,
  rotationResistance: 0,
  itemLabelRadius: 0.92,
  itemLabelRadiusMax: 0.3,
  itemLabelRotation: 180,
  itemLabelAlign: 'left',
  itemLabelColors: ['#fff'],
  itemLabelBaselineOffset: -0.07,
  // Check app.scss
  itemLabelFont: '"Mochiy Pop P One", "ZCOOL KuaiLe", "Jua"',
  itemLabelFontSizeMax: 55,
  itemBackgroundColors: [
    '#fdc963',
    '#00cca8',
    '#2b87e9',
    '#fd775b',
    '#ff4b78',
    '#c88857',
    '#a64a97',
    '#5b7c7d',
    '#715344',
    '#904e55',
    '#8b7856'
  ],
  rotationSpeedMax: 2000,
  lineWidth: 1,
  lineColor: '#fff',
  // image: './img/icon.png',
  // overlayImage: './img/image.png',
  items: []
};

const container = ref();

let wheel: Wheel | undefined = undefined;

const stopAndClearSound = () => {
  if (!wheel) return;

  wheel.onCurrentIndexChange = undefined;
  wheel.stop();
};

const playSound = () => {
  if (!TickSound.value) return;

  var src = TickSound.value.value.startsWith('data:')
    ? TickSound.value.value
    : `/sound/${TickSound.value.value}`;
  const audio = new Audio(src);
  audio.volume = 0.3;
  audio.play();
};

const isSpinning = ref(false);

const spin = () => {
  if (isSpinning.value) {
    return;
  }
  if (!wheel) return;

  wheel.onCurrentIndexChange = () => {
    if (!wheel) return;

    playSound();

    // Change rotation resistance based on current speed.
    // Provide a more entertaining performance.
    switch (true) {
      case wheel.rotationSpeed < 400:
        wheel.rotationResistance = -100;
        break;
      case wheel.rotationSpeed < 100:
        wheel.rotationResistance = -30;
        break;
      case wheel.rotationSpeed < 30:
        wheel.rotationResistance = -10;
        break;
    }
  };

  const availableItems = Items.value!.filter(item => item.label !== "Workshop");

  // Spin only if there are available items

  isSpinning.value = true;
  if (availableItems.length > 0) {
    wheel.rotationResistance = -400;
    wheel.spin(wheel.rotationSpeed + random.int(800, 1200));
  }

};

const dialog = useDialog();
const openCongratulationDialog = ($event: {
  type: 'rest';
  currentIndex: number;
  rotation: number;
}) => {
  dialog.open(CongratulationDialog, {
    props: {
      modal: true,
      showHeader: false,
      style: 'border: 0',
      contentStyle: 'border: 0; backgroundColor: transparent',
      dismissableMask: true
    },
    data: {
      item: Items.value![$event.currentIndex]
    }

  });
};
const checkAndRemoveWorkshop = () => {
  if (workshopOcc.value == 15 && itemService) {
    // Find the "Workshop" item in the items array
    const workshopItem = Items.value?.find(item => item.label === 'Workshop');

    if (workshopItem) {
      // Remove the "Workshop" item from the database and update the wheel
      itemService.removeItem(workshopItem);
      // Update the wheel with the new items array (excluding the removed item)
      wheel!.items = Items.value!.filter(item => item.label !== 'Workshop');
      // Reset the counter after removing the "Workshop" item
      // workshopOcc = 0;
      // localStorage.setItem('workshopOcc', '0'); // Update the localStorage value
    }
  }
};

const workshopOcc = ref(Number(localStorage.getItem('workshopOcc')) || 0);

onMounted(() => {
  watch(Items, (newValue) => (wheel!.items = newValue!));
  watch(LabelLength, (newValue) => {
    wheel!.itemLabelRadiusMax = 1 - newValue;
  });

  wheel = new Wheel(container.value, {
    ...properties,
    items: Items.value,
    itemLabelRadiusMax: 1 - LabelLength.value
  });

  wheel.spin(10);

  wheel.onRest = ($event) => {
    stopAndClearSound;
    isSpinning.value = false;
    openCongratulationDialog($event);

    const selectedLabel = Items.value![$event.currentIndex].label;
    if (selectedLabel === "Workshop") {
      workshopOcc.value += 1;
      localStorage.setItem('workshopOcc', workshopOcc.value.toString()); // Update the localStorage value
      checkAndRemoveWorkshop();
    }

  };

  // Workaround for itemLabelRadiusMax not working on first load.
  setTimeout(() => {
    wheel!.itemLabelRadiusMax = 1 - LabelLength.value;
  }, 50);
});
</script>

<style lang="scss" scoped>
@import 'primeflex/core/_variables.scss';

.spin-container {
  aspect-ratio: 1/1;
  width: 200vw;
  height: 90vh;

  margin-top: -3.5rem;
  margin-bottom: -10vh;
  position: relative;

  @media (min-width: map-get($breakpoints, 'sm')) {
    height: 100vh;
  }

  @media (min-width: map-get($breakpoints, 'md')) {
    height: 110vh;
  }
}

.image {
  object-position: center;
  object-fit: contain;

  aspect-ratio: 1/1;
  width: 200vw;
  height: 90vh;

  position: absolute;
  top: calc(calc(50%) - calc(90vh / 2));
  left: calc(calc(50%) - calc(200vw / 2));

  @media (min-width: map-get($breakpoints, 'sm')) {
    height: 100vh;
    top: calc(calc(50%) - calc(100vh / 2));
  }

  @media (min-width: map-get($breakpoints, 'md')) {
    height: 110vh;
    top: calc(calc(50%) - calc(110vh / 2));
  }
}

.button-container {
  margin-top: -5.5rem;

  button {
    z-index: 2;
    position: relative;

    $background-color: #0c0f1d;
    background: $background-color;

    &:hover {
      filter: brightness(1.3);
    }
  }
}

.icon {
  $icon-size: 10vh;
  cursor: pointer;

  width: $icon-size;
  height: $icon-size;
  border-radius: 50%;
  background-color: #fff;
  // background-image: image-set(
  //   '/img/icon.avif' type('image/avif'),
  //   '/img/icon.webp' type('image/webp'),
  //   '/img/icon.png' type('image/png')
  // );
  background-size: contain;
  // background-color: rgba(1, 1, 1, 0.5);

  position: absolute;
  top: calc(calc(50%) - calc($icon-size / 2));
  left: calc(calc(50%) - calc($icon-size / 2));

  &:hover {
    filter: brightness(1.1);
  }
}
</style>
