export const templateItems = [
  {
    group: 'TEKAB.DEV',
    label: 'Workshop',
    weight: 1,
    order: 0
  },
  {
    group: 'TEKAB.DEV',
    label: 'Jeux',
    weight: 1,
    order: 1
  },
  {
    group: 'TEKAB.DEV',
    label: 'Chocolaté',
    weight: 1,
    order: 2
  },
  {
    group: 'TEKAB.DEV',
    label: 'Autre tour',
    weight: 1,
    order: 3
  },
  {
    group: 'TEKAB.DEV',
    label: 'Quiz',
    weight: 1,
    order: 4
  },
  {
    group: 'TEKAB.DEV',
    label: 'Story Instagram',
    weight: 1,
    order: 5
  },
  {
    group: 'TEKAB.DEV',
    label: 'Game over',
    weight: 1,
    order: 6
  },
  {
    group: 'TEKAB.DEV',
    label: 'Photo Linkedin',
    weight: 1,
    order: 7
  }
];
